import React from 'react'
/* import Grid from '@material-ui/core/Grid' */
import { Layout } from '../component/Layout'
import { PageProps } from 'gatsby'
import OffMarketMap from '../component/page_component/OffMarketMap'
import { useMediaQuery } from '@material-ui/core'
import theme from '../Theme/theme'
import { useIntl } from 'gatsby-plugin-intl'
import hreflangConfig from '../utils/hreflangConfig'
import { Helmet } from 'react-helmet'

const OffMarket: React.FC<PageProps> = (props: any) => {
  const intl = useIntl()
  const {
    location,
    pageContext: { data },
  } = props
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const hreflangLinks = hreflangConfig['/fr/off-market']
  return (
    <Layout location={location} title={intl.formatMessage({ id: 'PageTitleOffmarket' })}>
      <Helmet>
        {hreflangLinks &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
      </Helmet>
      <OffMarketMap data={data} isSmDown={isSmDown} />
    </Layout>
  )
}

export default OffMarket
